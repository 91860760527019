import { Injectable } from '@angular/core';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertController: AlertController,
    private translate: TranslateService,
  ) { }

  async mostrarMissatgeAlert(msg) {
    const alert = await this.alertController.create({
      header: msg,
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant('general.ok'),
          handler: () => {

          }
        }
      ]
    });

    await alert.present();
  }

  async showDeleteConfirmMessage(msg: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: msg,
        buttons: [
          {
            text: this.translate.instant('general.no'),
            role: 'no',
            handler: () => resolve(false),
          },
          {
            text: this.translate.instant('general.si'),
            role: 'yes',
            handler: () => resolve(true),
          },
        ],
      });

      await alert.present();
    });
  }
}
