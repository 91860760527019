import { Component, NgZone } from '@angular/core';
import {ConfigService} from './services/essentials/config/config.service';
import { LoaderService } from './services/essentials/utils/loader.service';
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private ngZone: NgZone,
    private config: ConfigService,
    public loaderService: LoaderService,
    private androidFullScreen: AndroidFullScreen
  ) {
    this.ngZone.run(() => {
      this.androidFullScreen.isImmersiveModeSupported()
        .then(() => {
          this.androidFullScreen.immersiveMode();
          console.log('Immersive mode enabled');
        })
        .catch(err => console.info('Immersive mode not supported', err));
    });

    this.config.initI18n();
  }
}
