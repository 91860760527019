import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoaderService} from '../services/essentials/utils/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private loaderService: LoaderService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.url.includes('face-login')) {
      this.loaderService.show();
      return next.handle(request).pipe(
        finalize(() => this.loaderService.hide())
      );
    } else {
      return next.handle(request);
    }
  }
}
