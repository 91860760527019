import {Injectable, Injector} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {API_ENDPOINT} from '../../../../environments/environment';
import {AuthenticationMethod} from '../../../enums/authentication-method';
import {UserService} from '../../api/user.service';
import {AlertService} from '../utils/alert.service';
import {DeviceConfiguration} from '../../../models/device-configuration';
import {Action} from '../../../enums/action';
const COMPANY_KEY = 'company';
const WORKPLACE_KEY = 'workplace';
const WORKPLACE_CODE_KEY = 'workplace-code';
const DEFAULT_LANGUAGE = 'ca';
const ADMIN_TOKEN = 'token';
const EXIST_FINGERPRINT_SENSOR = 'exist_fingerprint_sensor';
const DEVICE_TOKEN = 'device_token';
const DEVICE_CONFIGURATION = 'device_configuration';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

  constructor(
    private injector: Injector,
    private translateService: TranslateService,
    private alertService: AlertService
  ) { }

  static removeCompany() {
      return localStorage.removeItem(COMPANY_KEY);
  }

  static setCompany(company: string) {
      return localStorage.setItem(COMPANY_KEY, company);
  }

  static getCompany() {
      return localStorage.getItem(COMPANY_KEY);
  }

  static setWorkplace(workplace: any) {
      if (workplace === null) {
        return null;
      }
      return localStorage.setItem(WORKPLACE_KEY, workplace);
  }

  static getWorkplace() {
      return localStorage.getItem(WORKPLACE_KEY);
  }

  static removeWorkplace() {
      return localStorage.removeItem(WORKPLACE_KEY);
  }

  static setWorkplaceCode(workplace: any) {
      if (workplace === null) {
        return null;
      }
      return localStorage.setItem(WORKPLACE_CODE_KEY, workplace);
  }

  static getWorkplaceCode() {
      return localStorage.getItem(WORKPLACE_CODE_KEY);
  }

  static removeWorkplaceCode() {
      return localStorage.removeItem(WORKPLACE_CODE_KEY);
  }

  static setAdminToken(token: any) {
    if (token === null) {
      return null;
    }
    return localStorage.setItem(ADMIN_TOKEN, token);
  }

  static getAdminToken() {
    return localStorage.getItem(ADMIN_TOKEN);
  }

  static removeAdminToken() {
    return localStorage.removeItem(ADMIN_TOKEN);
  }

  static getLanguage(){
    return localStorage.getItem('locale') || DEFAULT_LANGUAGE;
  }

  static getExistFingerprintSensor() {
    return localStorage.getItem(EXIST_FINGERPRINT_SENSOR) === 'true';
  }

  static setExistFingerprintSensor(existFingerprintSensor: boolean) {
    return localStorage.setItem(EXIST_FINGERPRINT_SENSOR, String(existFingerprintSensor));
  }

  static removeDeviceToken() {
    return localStorage.removeItem(DEVICE_TOKEN);
  }

  static setDeviceToken(deviceToken: string) {
    return localStorage.setItem(DEVICE_TOKEN, deviceToken);
  }

  static getDeviceToken() {
    return localStorage.getItem(DEVICE_TOKEN);
  }

  static setDeviceConfiguration(deviceConfiguration: DeviceConfiguration) {
    localStorage.setItem('locale', deviceConfiguration.locale);
    return localStorage.setItem(DEVICE_CONFIGURATION, JSON.stringify(deviceConfiguration));
  }

  static getDeviceConfiguration(): DeviceConfiguration{
    return localStorage.getItem(DEVICE_CONFIGURATION) ? Object.assign(new DeviceConfiguration(), JSON.parse(localStorage.getItem(DEVICE_CONFIGURATION))) : new DeviceConfiguration();
  }

  static removeDeviceConfiguration() {
    return localStorage.removeItem(DEVICE_CONFIGURATION);
  }

  static getDeviceName(): string {
    return ConfigService.getDeviceConfiguration().name;
  }

  static logout(){
    ConfigService.removeCompany();
    ConfigService.removeWorkplace();
    ConfigService.removeWorkplaceCode();
    ConfigService.removeDeviceToken();
    ConfigService.removeAdminToken();
    ConfigService.removeDeviceConfiguration();
  }

  getAuthenticationMethods(): Array<AuthenticationMethod> {
    return ConfigService.getDeviceConfiguration().authenticationMethods;
  }

  authenticationMethodConfigured(authenticationMethod: AuthenticationMethod) {
    return this.getAuthenticationMethods().includes(authenticationMethod);
  }

  getHiddenActions(): Array<Action> {
    return ConfigService.getDeviceConfiguration().hiddenActions;
  }

  actionConfigured(action: Action){
    return !this.getHiddenActions().includes(action);
  }

  refreshDeviceConfiguration() {
    const userService = this.injector.get<UserService>(UserService);
    const token = ConfigService.getDeviceToken();
    if (token !== null) {
      userService.getDeviceConfiguration(token).then(result => {
        ConfigService.setDeviceConfiguration(result);
        this.translateService.use(result.locale);
      }).catch(err => {
        this.alertService.mostrarMissatgeAlert(this.translateService.instant('deviceConfiguration.error.refresh'));
      });
    }
  }

  async unregisterDevice(): Promise<void> {
    return new Promise((resolve, reject) => {
      const userService = this.injector.get<UserService>(UserService);
      const token = ConfigService.getDeviceToken();
      if (token !== null) {
        userService.unregisterDevice(token).then(result => {
          ConfigService.logout();
          resolve();
        }).catch(err => {
          if (err.status === 404 ) {
            this.alertService.mostrarMissatgeAlert(this.translateService.instant('deviceConfiguration.error.unregisterNotFound'));
          }else {
            this.alertService.mostrarMissatgeAlert(this.translateService.instant('deviceConfiguration.error.unregister'));
          }
          reject();
        });
      }else{
        reject();
      }
    });
  }

  getAdminToken() {
    return ConfigService.getAdminToken();
  }

  getApiUrl() {
      return API_ENDPOINT.replace('{company}', localStorage.getItem('company'));
  }

  isProjectsEnabled(){
    return ConfigService.getDeviceConfiguration().isProjectsEnabled;
  }
  notAskProjectOnClockIn(){
    return ConfigService.getDeviceConfiguration().notAskProjectOnClockIn;
  }

  async initI18n() {
    this.translateService.addLangs(['ca', 'es']);
    const locale = localStorage.getItem('locale') || DEFAULT_LANGUAGE;
    this.translateService.setDefaultLang(locale);
    this.translateService.use(locale);
  }
}
