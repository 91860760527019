import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {ConfigService} from '../services/essentials/config/config.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const modifiedReq = request.clone({
      headers: request.headers
        .set('locale', ConfigService.getLanguage())
        .set('ngrok-skip-browser-warning', 'true'),
    });

    return next.handle(modifiedReq);
  }
}
