import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from '../services/essentials/config/config.service';

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function kioskAdminHttpClientCreator(http: HttpClient) {
  return new KioskAdminHttpClient(http);
}

@Injectable()
export class KioskAdminHttpClient{

  public constructor(
    private http: HttpClient
  ){}

  getRequestOptions(options?: IRequestOptions){
    if (!options) {
      options = {} as IRequestOptions;
    }
    if (!options.headers){
      options.headers = new HttpHeaders();
    }
    options.headers = options.headers.set('Authorization', `Bearer ${ConfigService.getAdminToken()}`);
    return options;
  }

  public get<T>(url: string, options?: IRequestOptions): Observable<T> {
    return this.http.get<T>(url, this.getRequestOptions(options));
  }

  public post<T>(url: string, body: any | null, options?: IRequestOptions): Observable<T> {
    return this.http.post<T>(url, body, this.getRequestOptions(options));
  }

  public put<T>(url: string, body: any | null, options?: IRequestOptions): Observable<T> {
    return this.http.put<T>(url, body, this.getRequestOptions(options));
  }

  public delete<T>(url: string, options?: IRequestOptions): Observable<T> {
    return this.http.delete<T>(url, this.getRequestOptions(options));
  }
}
