import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {KioskAdminHttpClient, kioskAdminHttpClientCreator} from './http-client/kiosk-admin-http-client';
import {LoaderInterceptor} from './interceptors/loader.interceptor';
import {ApiInterceptor} from './interceptors/api.interceptor';
import { NgxScannerFaceModule } from 'ngx-scanner-face';
import {IonicSelectableModule} from 'ionic-selectable';
import { NFC, Ndef } from '@awesome-cordova-plugins/nfc/ngx';
import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    // ServiceWorkerModule.register('ngsw-worker.js'),
    NgxScannerFaceModule,
    IonicSelectableModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: KioskAdminHttpClient, useFactory: kioskAdminHttpClientCreator, deps: [HttpClient] },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    NFC,
    Ndef,
    AndroidFullScreen,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    }
    // },
    // {
    //   provide: SwRegistrationOptions,
    //   useFactory: () => ({ enabled: environment.production }),
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
