import {AuthenticationMethod} from '../enums/authentication-method';
import {Action} from '../enums/action';

export class DeviceConfiguration {
  id: string;
  name: string;
  authenticationMethods: Array<AuthenticationMethod> = [AuthenticationMethod.code, AuthenticationMethod.userAndPassword];
  hiddenActions: Array<Action> = [];
  isProjectsEnabled = false;
  notAskProjectOnClockIn = false;
  locale: string;
}
