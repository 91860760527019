import { Injectable } from '@angular/core';
import {LoadingController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public loading = false;
  
  constructor(public loadingController: LoadingController
  ) { }

  public async show() {
    this.loading = true;
    // this.loadingController.getTop().then(hasLoading => {
    //   if (!hasLoading){
    //     this.loadingController.create().then(loading => loading.present());
    //   }
    // });
  }

  public hide() {
    this.loading = false;

    // setTimeout(() => {
    //   this.loadingController.getTop().then((hasLoading) => {
    //     if (hasLoading){
    //       this.loadingController.dismiss();
    //     }
    //   });
    // }, 1000);
  }
}
