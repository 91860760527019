import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../essentials/config/config.service';
import {DeviceConfiguration} from '../../models/device-configuration';

const PING_PATH = '/ping';
const IP_PATH = '/users/me/ip';
const CLOCKINOUT_PATH = '/employees/timesheet/auto';
const BREAK_PATH = '/employees/timesheet/break';
const LOGIN_KIOSK_ADMIN = '/users/login/kioskAdmin';
const DEVICES_PATH = '/devices';
const DEVICES_REGISTER_PATH = DEVICES_PATH + '/register';
const DEVICES_UNREGISTER_PATH = DEVICES_PATH + '/unregister';
const DEVICES_CONFIGURATION_PATH = '/configuration';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private config: ConfigService) { }

    async ping(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(this.config.getApiUrl() + PING_PATH).subscribe(res => {
                resolve(res);
            }, error1 => {
                reject();
            });
        });
    }

    async clockInOut(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post(this.config.getApiUrl() + CLOCKINOUT_PATH, data).subscribe(res => {
                resolve(res);
            }, error => {
                reject(error);
            });
        });
    }

  async faceLogin(image = null): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(this.config.getApiUrl() + '/face-login', {
        faceBase64Img: image,
      }).subscribe(async response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  async generateOtp(form): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(this.config.getApiUrl() + '/generate-otp', form).subscribe(async response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }


    async registerBreak(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post(this.config.getApiUrl() + BREAK_PATH, data).subscribe(res => {
                resolve(res);
            }, error1 => {
                reject();
            });
        });
    }

  async loginKioskAdmin(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.config.getApiUrl() + LOGIN_KIOSK_ADMIN, data).subscribe(res => {
        resolve(res);
      }, error => {
        reject(error.error.message);
      });
    });
  }

  async registerDevice(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.config.getApiUrl() + DEVICES_REGISTER_PATH, data).subscribe(res => {
        resolve(res);
      }, error1 => {
        reject(error1);
      });
    });
  }

  async getDeviceConfiguration(deviceToken: string): Promise<DeviceConfiguration> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.getApiUrl() + DEVICES_PATH + '/' + deviceToken  + DEVICES_CONFIGURATION_PATH).subscribe(res => {
        resolve(Object.assign(new DeviceConfiguration(), res));
      }, error1 => {
        reject();
      });
    });
  }

  async unregisterDevice(deviceToken: string): Promise<any> {

    const data = {
      device_token: deviceToken
    };

    return new Promise((resolve, reject) => {
      this.http.post(this.config.getApiUrl() + DEVICES_UNREGISTER_PATH, data).subscribe(res => {
        resolve(res);
      }, error1 => {
        reject(error1);
      });
    });
  }

}
